import {
  alpha,
  alphaNum,
  email,
  maxLength,
  maxValue,
  minLength,
  minValue,
  numeric,
  required,
  sameAs,
  url
} from '@vuelidate/validators';
import {uniqBy} from 'lodash';

export const validationForm = formInputs => {
  const validationFieldsStatuses = [];
  const uniqFields = uniqBy(formInputs.reverse(), 'name');
  uniqFields.forEach(e => {
    if (e) {
      e.v$.$touch();
      validationFieldsStatuses.push(e.v$.$invalid);
    }
  });
  // return status valid form
  return !validationFieldsStatuses.includes(true);
};

export const clearValidation = formInputs => {
  const uniqFields = uniqBy(formInputs?.reverse(), 'name');
  uniqFields?.forEach(e => {
    if (e) {
      e.v$.$reset();
    }
  });
};

const step = num => {
  return value => {
    return Number.isInteger(value / num);
  };
};

const usernameRule = value => {
  return /^[a-zA-Z0-9_]+$/.test(value);
};
const validateSubdomain = subdomain => {
  const pattern = /^[a-zA-Z0-9_-]+$/;
  return pattern.test(subdomain);
};

export const transformValidationRules = rules => {
  const formattedRules = {};
  Object.keys(rules).forEach(key => {
    if (key === 'maxLength') {
      formattedRules[key] = maxLength(rules[key]);
    }
    if (key === 'maxValue') {
      formattedRules[key] = maxValue(rules[key]);
    }
    if (key === 'minValue') {
      formattedRules[key] = minValue(rules[key]);
    }
    if (key === 'minLength') {
      formattedRules[key] = minLength(rules[key]);
    }
    if (key === 'required') {
      if (rules[key]) formattedRules[key] = required;
    }
    if (key === 'email') {
      formattedRules[key] = email;
    }
    if (key === 'numeric') {
      formattedRules[key] = numeric;
    }
    if (key === 'url') {
      formattedRules[key] = url;
    }
    if (key === 'alpha') {
      formattedRules[key] = alpha;
    }
    if (key === 'urlSlug') {
      formattedRules[key] = validateSubdomain;
    }
    if (key === 'alphaNum') {
      formattedRules[key] = alphaNum;
    }
    if (key === 'integer') {
      formattedRules[key] = step(rules[key]);
    }
    if (key === 'requiredTrue') {
      if (rules[key]) formattedRules[key] = sameAs(rules[key]);
    }
    if (key === 'username') {
      formattedRules[key] = usernameRule;
    }
  });
  return formattedRules;
};
