<template>
  <div
    class="main-checkbox"
    :class="[
      size,
      {
        readonly: readonly,
        error: !!v$.$errors.length
      }
    ]"
  >
    <div class="main-checkbox__container">
      <input
        type="checkbox"
        :name="name"
        :id="`input-${id}`"
        :value="value"
        :readonly="readonly"
        :checked="checked"
        :disabled="disabled"
        @input="handleInput(value)"
      />
      <slot name="label">
        <label :for="`input-${id}`" :class="{'input--tooltip': !!tooltip}">
          <slot>{{ label }}</slot>
          <template v-if="tooltip">
            <Tooltip :text="tooltip">
              <Icon name="question-circle" size="small" />
            </Tooltip>
          </template>
        </label>
      </slot>
    </div>
    <div class="main-input__errors" v-if="serverErrors">
      <span class="main-input__errors-item" :key="index" v-for="(item, index) in serverErrors">
        {{ item }}
      </span>
    </div>
    <div class="main-input__errors" v-if="v$.$errors.length">
      <span class="main-input__errors-item" :key="index" v-for="(item, index) in v$.$errors">
        {{ $t(`validationField_${item.$validator}`, {max: item.$params.max, min: item.$params.min}) }}
      </span>
    </div>
  </div>
</template>
<script>
import Icon from '@/components/atoms/Icon.vue';
import Tooltip from '@/components/atoms/Tooltip.vue';

import {transformValidationRules} from '@/helpers/validation';

import useVuelidate from '@vuelidate/core';
import {reactive, toRefs, watchEffect} from 'vue';

export default {
  components: {
    Icon,
    Tooltip
  },
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: [Number, String],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    name: {
      type: [Number, String],
      default: ''
    },
    serverErrors: {},
    value: {},
    rules: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['on-update'],
  setup(props, {emit}) {
    const {rules, checked} = toRefs(props);

    const state = reactive({
      inputVal: false
    });

    watchEffect(() => {
      state.inputVal = checked.value;
    });

    const validationRules = {
      inputVal: transformValidationRules(rules.value)
    };
    const v$ = useVuelidate(validationRules, state);

    const handleInput = $event => {
      state.inputVal = !state.inputVal;
      emit('on-update', $event);
    };

    return {
      v$,
      handleInput
    };
  }
};
</script>
<style lang="scss">
.main-checkbox {
  &.readonly {
    pointer-events: none;
  }
  &.large {
    label {
      font-size: 16px;
      padding-left: 28px;
      &::after {
        width: 18px;
        height: 18px;
      }
      &::before {
        top: 2px;
        left: 3px;
        width: 14px;
        height: 14px;
      }
    }
  }

  input {
    display: none;
  }

  label {
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding-left: 23px;
    min-height: 16px;
    font-size: 14px;
    padding-top: 1px;
    &.input--tooltip {
      display: flex;
      justify-content: flex-start;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: -1px;
      width: 16px;
      height: 16px;
      background: #ffffff;
      border: 1px solid rgba(17, 17, 17, 0.25);
      border-radius: 3px;
    }
    &::before {
      content: '';
      position: absolute;
      display: block;
      left: 3px;
      top: 2px;
      width: 12px;
      height: 12px;
      background: url('../../assets/images/svg/checked-icon.svg') center center no-repeat;
      background-size: contain;
      z-index: 2;
      opacity: 0;
    }
  }

  input:checked + label:before {
    opacity: 1;
  }
}
</style>
