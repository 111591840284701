<template>
  <div class="tooltip">
    <div class="tooltip-wrap">
      <div class="tooltip-slot" @mouseenter="show = true" @mouseleave="show = false">
        <slot></slot>
      </div>
      <transition name="slide-y-reverse" mode="in-out">
        <div v-show="show" class="tooltip-hint">
          {{ text }}
        </div>
      </transition>
    </div>
  </div>
</template>
<script setup>
import {defineProps, ref} from 'vue';

defineProps({
  text: {
    type: String
  }
});
const show = ref(false);
</script>
<style lang="scss">
.tooltip {
  position: relative;
  z-index: 4;

  &-wrap {
    position: relative;
  }

  &-hint {
    position: absolute;
    top: 20px;
    left: -20px;
    background: $white;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
    padding: 10px;
    font-size: 13px;
    line-height: 1.2;
    width: 300px;
    border-radius: 5px;
    z-index: 12;
  }

  &-slot {
    position: relative;
    z-index: 10;
  }
}
</style>
